//navbar
@mixin NavBar {
  font-size: 18px;

  margin-top: 10px;
}

.NavBar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  padding-right: 0% !important;
  height: 5.5rem !important;
  --bs-gutter-x: 2.5rem !important;

  @include NavBar;
}

//hero
.DigiPlusPageHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 80vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

@media only screen and (min-width: 650px) {
  .DigiPlusPageHeroBg {
    background: url(/src/assets/images/hero/digiplushero.png);
    background-size: cover;
    position: relative;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 80vh;
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .DigiPlusPageHeroBg {
    background: url(/src/assets/images/hero/digiplusherosm.png);
    background-size: cover;
    position: relative;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 80vh;
    width: 100%;
  }
}
.DigiPlusPageHeroBg h1 {
  position: relative;
}
.DigiPlusPageHeroBg h2 {
  position: relative;
}
.AboutPageHeroBg p {
  position: relative;
}
.DigiPlusPageHeroBg .DigiPlusRequestBtn {
  position: relative;
}

.eightGearImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/eightgear.png);
  background-size: cover;
  position: relative;
  height: 30rem;
  width: 100%;
}

.FUTAImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/futa.png);
  background-size: cover;
  position: relative;
  height: 30rem;
  width: 100%;
}

.sterlingImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/sterling.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}
.innkeeperImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/innkeeper.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}
.pearlMutualImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/pearlmutual.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}
.smedanImg {
  border-radius: 20px;
  background: url(/src/assets/images/digiplus/responsibilitymatrix/smedan.png);
  background-size: cover;
  position: relative;
  height: 60vh;
  width: 100%;
}

.imgCardBg {
  bottom: 10px;
  //   width: 22rem;
  left: 50%;
  width: 20rem;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin-bottom: 0.7rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.keyBoxShadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
